import LocalStorageKey from 'config/localStorageKey';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import {
  apiKeyHeader,
  apiTokenHeader
} from 'services/backofficeIntegration/http/backofficeHeaders';
import { makeBackofficeUrl } from 'services/backofficeIntegration/http/backofficeUrls';
import { processEventStream } from 'services/backofficeIntegration/http/processEventStream';
import { safeEnum } from 'utils/safeEnum';
import { assert } from 'utils/typescript/assert';

export const EventType = safeEnum.make({
  start: 'start-generation',
  update: 'update-generation',
  end: 'end-generation',
  error: 'error'
} as const);

export type EventType = safeEnum.infer<typeof EventType>;

export type StartEvent = {
  type: typeof EventType.start;
  generation_id: string;
  text_item_id: string;
};

export type UpdateEvent = {
  type: typeof EventType.update;
  text_item_id: string;
  text_delta: string;
};

export type EndEvent = { type: typeof EventType.end; text_item_id: string };

export type ErrorEvent = { type: typeof EventType.error; message: string };

export type EventDto = StartEvent | UpdateEvent | EndEvent | ErrorEvent;

export class StreamError extends Error {
  event: ErrorEvent;
  constructor(event: ErrorEvent) {
    super('Stream error');
    this.event = event;
  }
}

export type RequestParams = {
  audience_model_id: string;
  output_type: string;
  n_text_items?: number;
  n_times?: number;
  text?: string;
  keywords?: string;
  keywords2?: string;
  tonality?: string[];
  personality_id?: number;
  information_ids?: number[];
};

async function sendRequest(props: RequestParams) {
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);
  const isApiCustomer = localStorage.getItem(LocalStorageKey.IsApiCustomer) === '1';
  assert(!!apiToken, 'Missing api token');

  trackingWrapper.track('generateTexts', {
    url: window.location.pathname,
    location: 'ai_writer_flash_actions',
    input: props
  });

  return await fetch(makeBackofficeUrl('/word-embedding/generate_text_stream'), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
      ...apiTokenHeader(apiToken),
      ...apiKeyHeader({ isApiCustomer })
    },
    method: 'POST',
    body: JSON.stringify(props)
  });
}

export const httpGenerateTextStream = {
  callStreamEndpoint: async (props: {
    request: RequestParams;
    handlers: {
      onStart?: (event: StartEvent) => void;
      onUpdate?: (event: UpdateEvent) => void;
      onEnd?: (event: EndEvent) => void;
    };
    abort?: AbortSignal;
  }) => {
    const { handlers, request, abort } = props;
    const { onUpdate, onStart, onEnd } = handlers;
    const response = await sendRequest(request);

    await processEventStream({
      response,
      abort,
      onEvent: ({ event, cancel }) => {
        if (event.type === 'event') {
          const dataString = event.data;
          const data = JSON.parse(dataString) as EventDto;

          switch (data.type) {
            case EventType.start: {
              onStart?.(data);
              return;
            }
            case EventType.update: {
              onUpdate?.(data);
              return;
            }
            case EventType.end: {
              onEnd?.(data);
              return;
            }
            case EventType.error: {
              cancel();
              throw new StreamError(data);
            }
          }
        }
      }
    });
  }
};
